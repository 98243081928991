import React from "react"
import { Box, ToggleButtonGroup, ToggleButton, Container } from "@mui/material"
import { handleChangeSection } from "../mqtt/appToInstrument"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"

const Section = () => {
  const [alignment, setAlignment] = React.useState<string | null>("1")
  const isSmallScreen = useIsSmallScreen()

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    setAlignment(newAlignment)
  }

  return (
    <Container sx={{ paddingTop: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <h3>Välj del av låten</h3>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: isSmallScreen ? "100%" : "50%" }}>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            fullWidth
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton
              value="1"
              aria-label="Section one"
              onClick={() => handleChangeSection("1")}
            >
              1
            </ToggleButton>
            <ToggleButton
              value="2"
              aria-label="Section two"
              onClick={() => handleChangeSection("2")}
            >
              2
            </ToggleButton>
            <ToggleButton
              value="3"
              aria-label="Section three"
              onClick={() => handleChangeSection("3")}
            >
              3
            </ToggleButton>
            <ToggleButton
              value="4"
              aria-label="Section four"
              onClick={() => handleChangeSection("4")}
            >
              4
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
    </Container>
  )
}

export default Section
