export enum SONGS {
  PIPPI = "pippi",
  ACOUSTIC = "acoustic",
  ELECTRO = "electro",
  TROLLMOR = "trollmor",
  DEADLYMEADLY = "deadly-meadly",
  SOUNDEFFECTS = "soundeffects",
  PERCUSSION = "percussion",
  POP = "pop",
  BLINKA = "blinka",
  MOTOR = "motor",
  FARM = "farm",
  HUMAN = "human",
  CHURCH = "church",
  CHRISTMAS = "christmas",
  WATER = "water",
  ROCKAFETT = "rocka-fett",
}

export enum BACKINGTRACK {
  PLAY = "play-backingtrack",
  STOP = "stop-backingtrack",
  PLAY_METRONOME = "play-metronome",
  STOP_METRONOME = "stop-metronome",
}

export enum CATEGORY {
  MUSIC = "music",
  ATMOSPHERE = "atmosphere",
}
