import { MQTT_API } from "../utils/api"
import { SONGS } from "../utils/types"

import { mqttClient, isConnected } from "./getMqttClient"

export function waitUntilConnected(timeout: number = 10000): Promise<void> {
  return new Promise((resolve, reject) => {
    const interval = 100
    let elapsedTime = 0

    const checkConnection = setInterval(() => {
      if (isConnected) {
        clearInterval(checkConnection)
        resolve()
      }

      elapsedTime += interval

      if (elapsedTime >= timeout) {
        clearInterval(checkConnection)
        reject(new Error("Connection timeout"))
      }
    }, interval)
  })
}

export function handleChangeSong(message: SONGS) {
  let changeSongTopic = MQTT_API.INSTRUMENTS.ALL + MQTT_API.TOPIC.SONG
  mqttClient?.send(changeSongTopic, message, 1, false)
}

export function handleBackingtrack(message: string) {
  let backingtrackTopic = MQTT_API.INSTRUMENTS.ALL + MQTT_API.TOPIC.BACKINGTRACK
  mqttClient?.send(backingtrackTopic, message, 1, false)
}

export function handleMetronome(value: string) {
  let metronomeTopic = MQTT_API.INSTRUMENTS.ALL + MQTT_API.TOPIC.METRONOME
  mqttClient?.send(metronomeTopic, value, 1, false)
}

export function handleChangeSection(message: string) {
  let changeSectionTopic = MQTT_API.INSTRUMENTS.ALL + MQTT_API.TOPIC.SECTION
  mqttClient?.send(changeSectionTopic, message, 1, false)
}

export function handleChangeVolume(value: string, instrument: string) {
  let changeVolumeTopic = instrument + MQTT_API.TOPIC.VOLUME
  mqttClient?.send(changeVolumeTopic, value, 0, false)
}

export function handleChangeMasterVolume(value: string) {
	let changeMasterVolume = MQTT_API.TOPIC.MASTER_VOLUME
	mqttClient?.send(changeMasterVolume, value, 0, false)
}
