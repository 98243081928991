import React, { useEffect, useState } from "react"
import buttons from "../images/Instrument-Buttons.png"
import levers from "../images/Instrument-Levers.png"
import joysticks from "../images/Instrument-Joysticks.png"
import touch from "../images/Instrument-Touch.png"

import { Box, Container, Slider } from "@mui/material"
import { MQTT_API } from "../utils/api"

import { useIsSmallScreen } from "../hooks/useIsSmallScreen"
import { handleChangeVolume } from "../mqtt/appToInstrument"

// Returnerar korrekt bild beroende på vilket instrument id vi anropar med
function handleImageSource(id: any): any {
  if (id === MQTT_API.INSTRUMENTS.BUTTONS) {
    return buttons
  } else if (id === MQTT_API.INSTRUMENTS.JOYSTICKS) {
    return joysticks
  } else if (id === MQTT_API.INSTRUMENTS.LEVERS) {
    return levers
  } else if (id === MQTT_API.INSTRUMENTS.TOUCH) {
    return touch
  } else {
    return buttons
  }
}

const Instruments = ({ data }: any) => {
  const isSmallScreen = useIsSmallScreen()
  // Use state to manage the volume values for each instrument
  const [instrumentVolumes, setInstrumentVolumes] = useState<{
    [key: string]: number
  }>({})

  const handleSlider = (
    event: React.ChangeEvent<HTMLInputElement>,
    instrument: any
  ) => {
    const newVolume = parseFloat(event.target.value)
    setInstrumentVolumes((prevVolumes) => ({
      ...prevVolumes,
      [instrument]: newVolume,
    }))
    handleChangeVolume(newVolume.toString(), instrument)
  }

  // Fetch initial volume values from storage when the component mounts
  useEffect(() => {
    const storedVolumesString = localStorage.getItem("instrumentVolumes")
    if (storedVolumesString) {
      const storedVolumes = JSON.parse(storedVolumesString)
      setInstrumentVolumes(storedVolumes)
    }
  }, [])

  // Save volume values to storage whenever they change
  useEffect(() => {
    localStorage.setItem("instrumentVolumes", JSON.stringify(instrumentVolumes))
  }, [instrumentVolumes])

  return (
    <Container
      sx={{
        paddingTop: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "50vh",
      }}
    >
      {data?.length ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr", // Adjust the number of columns as needed
            gap: "1rem", // Adjust the gap between items
          }}
        >
          {data?.map((instrument: any, idx: number) => (
            <Box key={idx} sx={{ display: "flex" }}>
              <Slider
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: "slider-vertical",
                  },
                }}
                aria-label="Slider"
                orientation="vertical"
                // getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                value={instrumentVolumes[instrument] ?? 1}
                min={0}
                max={instrument === "/touch1" ? 1 : 2}
                step={0.1}
                onChange={(event: any) => handleSlider(event, instrument)}
              />

              <Box>
                <img
                  src={handleImageSource(instrument)}
                  alt="Bild på instrument"
                  width="80%"
                />
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Box>
          <h4>Väntar på Funki instrument...</h4>
        </Box>
      )}
    </Container>
  )
}

export default Instruments
