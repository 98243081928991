import * as React from "react"
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material"
import { handleChangeSong } from "../mqtt/appToInstrument"
import { SONGS } from "../utils/types"

interface ISongCard {
  title: string
  description?: string
  img: any
  song: SONGS
  isSelected?: boolean
  setSelectedCard: (song: SONGS) => void
}

const SongCard = ({
  title,
  description,
  img,
  song,
  // setShowSections,
  isSelected,
  setSelectedCard,
}: ISongCard) => {
  return (
    <Card
      onClick={() => {
        handleChangeSong(song)
        setSelectedCard(song)
      }}
      sx={{
        display: "flex",
        background:
          "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(11, 5, 4, 0.2))",
        width: 330,
        height: 168,
        margin: 1,
        justifyContent: "space-between",
        cursor: "pointer",
        border: isSelected ? "2px solid green" : "",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {title}
          </Typography>
          <Typography variant="subtitle1" component="div">
            {description}
          </Typography>
        </CardContent>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
        <CardMedia
          component="img"
          sx={{ width: 136, height: 136 }}
          image={img}
          alt="Bild av låt"
        />
      </Box>
    </Card>
  )
}

export default SongCard
