import React from "react"
import { Box, Container } from "@mui/material"
import { SONGS, CATEGORY } from "../utils/types"

import SongCard from "./Card"
import acoustic from "../images/acoustic.jpg"
import deadlymeadly from "../images/deadly-meadly.jpg"
// import animals from "../images/animal.jpg"
import percussion from "../images/song-percussion.jpg"
import pop from "../images/song-pop.jpg"
import blinka from "../images/blinka.png"
import car from "../images/car.png"
import farm from "../images/farm.png"
import christmas from "../images/christmas.png"
import water from "../images/water.png"
import rocka from "../images/rockafett.png"
import human from "../images/human.png"
import trollmor from "../images/trollmor.png"
import church from "../images/church.png"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"

export function handleSelectedSong(song: SONGS) {
  // Set selected song to be used throught the app
  localStorage.setItem("selectedSong", song)
}
interface Isong {
  title: string
  description?: string
  img: any
  song: SONGS
  category: CATEGORY
}

const songList: Isong[] = [
  {
    title: "Akustisk",
    description: "Akustisk låt",
    img: acoustic,
    song: SONGS.ACOUSTIC,
    category: CATEGORY.MUSIC,
  },
  {
    title: "Deadly Meadly",
    description: "E-dur",
    img: deadlymeadly,
    song: SONGS.DEADLYMEADLY,
    category: CATEGORY.MUSIC,
  },
  {
    title: "Kristendom",
    description: "Ljud från kyrkan",
    img: church,
    song: SONGS.CHURCH,
    category: CATEGORY.ATMOSPHERE,
  },
  {
    title: "Jullåt",
    description: "God jul!",
    img: christmas,
    song: SONGS.CHRISTMAS,
    category: CATEGORY.MUSIC,
  },
  {
    title: "Vatten",
    description: "Plums!",
    img: water,
    song: SONGS.WATER,
    category: CATEGORY.ATMOSPHERE,
  },
  {
    title: "Slagverk",
    description: "",
    img: percussion,
    song: SONGS.PERCUSSION,
    category: CATEGORY.MUSIC,
  },
  {
    title: "Blinka Lilla Stjärna",
    description: "",
    img: blinka,
    song: SONGS.BLINKA,
    category: CATEGORY.MUSIC,
  },
  {
    title: "Motorlåda",
    description: "Brum brum!",
    img: car,
    song: SONGS.MOTOR,
    category: CATEGORY.ATMOSPHERE,
  },
  {
    title: "Bondgården",
    description: "",
    img: farm,
    song: SONGS.FARM,
    category: CATEGORY.ATMOSPHERE,
  },
  {
    title: "Trollmor",
    description: "",
    img: trollmor,
    song: SONGS.TROLLMOR,
    category: CATEGORY.MUSIC,
  },
  {
    title: "Rocka Fett",
    description: "",
    img: rocka,
    song: SONGS.ROCKAFETT,
    category: CATEGORY.MUSIC,
  },
  {
    title: "Människolådan",
    description: "",
    img: human,
    song: SONGS.HUMAN,
    category: CATEGORY.ATMOSPHERE,
  },
  {
    title: "Pop",
    description: "",
    img: pop,
    song: SONGS.POP,
    category: CATEGORY.MUSIC,
  },
]

const ChooseSong = ({ onSongSelect }: any) => {
  const isSmallScreen = useIsSmallScreen()
  const selectedSong = localStorage.getItem("selectedSong")

  const handleCardClick = (song: SONGS) => {
    handleSelectedSong(song)
    onSongSelect(song) // Notify the parent component of the selected song
  }

  return (
    <Container
      sx={{
        paddingTop: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box paddingTop={1}>
        <h3>Musik</h3>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
        }}
      >
        {songList.map(
          (song: any, idx: number) =>
            song.category === CATEGORY.MUSIC && (
              <MusicCategory
                song={song}
                selectedSong={selectedSong}
                handleCardClick={handleCardClick}
                key={idx}
              />
            )
        )}
      </Box>
      <Box paddingTop={3}>
        <h3>Ljudlandskap</h3>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: isSmallScreen ? "1fr" : "1fr 1fr 1fr",
          flexDirection: isSmallScreen ? "column" : "row",
          justifyContent: "center",
        }}
      >
        {songList.map(
          (song: any, idx: number) =>
            song.category === CATEGORY.ATMOSPHERE && (
              <AtmosphereCategory
                song={song}
                selectedSong={selectedSong}
                handleCardClick={handleCardClick}
                key={idx}
              />
            )
        )}
      </Box>
    </Container>
  )
}

const MusicCategory = ({ song, selectedSong, handleCardClick }: any) => {
  return (
    <SongCard
      title={song.title}
      description={song.description}
      img={song.img}
      song={song.song}
      isSelected={selectedSong === song.song}
      setSelectedCard={handleCardClick}
    />
  )
}

const AtmosphereCategory = ({ song, selectedSong, handleCardClick }: any) => {
  return (
    <SongCard
      title={song.title}
      description={song.description}
      img={song.img}
      song={song.song}
      isSelected={selectedSong === song.song}
      setSelectedCard={handleCardClick}
    />
  )
}

export default ChooseSong
