import React, { useState } from "react"
import { Container } from "@mui/material"
import ChooseSong from "../components/ChooseSong"
import { SONGS } from "../utils/types"

const Library = () => {
  const [selectedSong, setSelectedSong] = useState<SONGS | null>(null)

  // Define a function to handle song selection
  const handleSongSelect = (song: SONGS | null) => {
    setSelectedSong(song)
  }

  return (
    <Container>
      <ChooseSong onSongSelect={handleSongSelect} />
    </Container>
  )
}

export default Library
