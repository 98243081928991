import React, { useEffect, useState } from "react"
import "./App.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { setMessageCallback } from "./mqtt/getMqttClient"
import Header from "./components/Header"
import WelcomePage from "./pages/Welcome"
import { waitUntilConnected } from "./mqtt/appToInstrument"
import Library from "./pages/Library"
import About from "./pages/About"
// import Footer from "./components/Footer"
import { ROUTES } from "./utils/api"
import { Container } from "@mui/system"

function App() {
  const [isMqttConnected, setIsMqttConnected] = useState(false)
  const [users, setUsers] = useState<any[]>([])

  console.log("users: ", users)

  const hasLoaded = isMqttConnected

  useEffect(() => {
    const userAddedMap = new Map()

    setMessageCallback(
      (connectedInstrument: any) => {
        // console.log(`Connected instrument: ${connectedInstrument}`)

        // Check if the user has been added recently or exists in the list
        if (
          !userAddedMap.has(connectedInstrument) &&
          !users.includes(connectedInstrument)
        ) {
          console.log(`Adding '${connectedInstrument}' to users list`)
          setUsers((prevUsers) => [...prevUsers, connectedInstrument])

          // Mark the user as recently added with a timestamp
          userAddedMap.set(connectedInstrument, Date.now())
        }
      },
      (disconnectedInstrument: any) => {
        // console.log(`Disconnected instrument: ${disconnectedInstrument}`)
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user !== disconnectedInstrument)
        )
      }
    )

    return () => {
      // Clean up subscription or perform other actions when the component unmounts
    }
  }, [users])

  useEffect(() => {
    waitUntilConnected()
      .then(() => {
        console.log("MQTT connected")
        setIsMqttConnected(true)
      })
      .catch((error) => {
        console.error("MQTT connection failed:", error)
        setIsMqttConnected(false)
      })
  }, [])

  return (
    <Router>
      {hasLoaded ? (
        <>
          <Header />
          <Container>
            <Routes>
              <Route
                path={ROUTES.HOME}
                element={<WelcomePage data={users} />}
              />
              <Route path={ROUTES.LIBRARY} element={<Library />} />
              <Route path={ROUTES.ABOUT} element={<About />} />
            </Routes>
          </Container>
          {/* <Footer /> */}
        </>
      ) : (
        <h1>...Loading</h1>
      )}
    </Router>
  )
}

export default App
