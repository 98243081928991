import React, { useState, useEffect } from "react"
import { Box, Slider, Container } from "@mui/material"
import { handleChangeMasterVolume } from "../mqtt/appToInstrument"

const MasterVolume = () => {
  const [sliderValue, setSliderValue] = useState(1)

  const handleSlider = (event: Event, newValue: number | number[]) => {
    const newSliderValue = newValue as number
    setSliderValue(newSliderValue)
    handleChangeMasterVolume(newSliderValue.toString())
  }

  useEffect(() => {
    const initialValue = localStorage.getItem("masterSliderValue")
    setSliderValue(initialValue ? parseFloat(initialValue) : 1)
  }, [])
  useEffect(() => {
    localStorage.setItem("masterSliderValue", sliderValue.toString())
  }, [sliderValue])

  return (
    <Container
      sx={{
        paddingTop: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box width="70%">
        <h3>Master volym</h3>
        <Slider
          sx={{
            '& input[type="range"]': {
              WebkitAppearance: "slider-vertical",
            },
          }}
          aria-label="Slider"
          value={sliderValue}
          min={0}
          max={2}
          step={0.2}
          onChange={handleSlider}
        />
      </Box>
    </Container>
  )
}

export default MasterVolume
