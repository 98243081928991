import React, { useState } from "react"

import logo from "../assets/funkilogo.png"
import { AppBar, Toolbar, Button, Box } from "@mui/material"
import IconHome from "../assets/icons/IconHome.png"
import IconLibrary from "../assets/icons/IconLibrary.png"
import IconQuestionMark from "../assets/icons/IconQuestionMark.png"
import { Link } from "react-router-dom"
import { ROUTES } from "../utils/api"
import { useIsSmallScreen } from "../hooks/useIsSmallScreen"

const Header = () => {
  const [selectedTab, setSelectedTab] = useState("/")

  const isSmallScreen = useIsSmallScreen()

  const handleSelectedTab = (tab: string) => {
    setSelectedTab(tab)
  }

  const buttonStyles = {
    textTransform: "none",
    color: "black",
    fontFamily: "Raleway",
    fontSize: "18px",
    textDecoration: "none",
    fontWeight: "600px",
  }

  const linkStyles = {
    textDecoration: "none",
    color: "inherit",
  }

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link to={ROUTES.LIBRARY} style={linkStyles}>
            <Button
              sx={buttonStyles}
              onClick={() => handleSelectedTab(ROUTES.LIBRARY)}
            >
              {
                <img
                  src={IconLibrary}
                  alt="Bibliotek"
                  style={{ paddingRight: "5px" }}
                />
              }
              <h5
                style={{
                  fontWeight: selectedTab === ROUTES.LIBRARY ? "bold" : "",
                }}
              >
                Bibliotek
              </h5>
            </Button>
          </Link>
          <Link to={ROUTES.HOME} style={linkStyles}>
            <Button
              sx={buttonStyles}
              onClick={() => handleSelectedTab(ROUTES.HOME)}
            >
              {<img src={IconHome} alt="Hem" style={{ paddingRight: "5px" }} />}
              <h5
                style={{
                  fontWeight: selectedTab === ROUTES.HOME ? "bold" : "",
                }}
              >
                Hem
              </h5>
            </Button>
          </Link>
        </Box>
        {!isSmallScreen && (
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <img src={logo} alt="Logo" style={{ height: "35px" }} />
          </Box>
        )}

        <Box>
          <Link to={ROUTES.ABOUT} style={linkStyles}>
            <Button
              sx={buttonStyles}
              onClick={() => handleSelectedTab(ROUTES.ABOUT)}
            >
              {
                <img
                  src={IconQuestionMark}
                  alt="Hem"
                  style={{ paddingRight: "5px", width: "24px" }}
                />
              }
              <h5
                style={{
                  fontWeight: selectedTab === ROUTES.ABOUT ? "bold" : "",
                }}
              >
                Om
              </h5>
            </Button>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
