import React from "react"
import { Container, Box } from "@mui/material"
import IconHeart from "../assets/icons/IconHeart.png"

const About = () => {
  return (
    <Container>
      <Box
        sx={{
          paddingTop: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "80vh",
        }}
      >
        <h3>
          With{" "}
          {
            <img
              src={IconHeart}
              alt="Hjärta"
              style={{ width: "24px", height: "24px" }}
            />
          }{" "}
          from Funki
        </h3>
        <h3>v. 1.1.0</h3>
      </Box>
    </Container>
  )
}

export default About
